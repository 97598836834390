"use client";

import styled from "styled-components";

import { scrollBarStyle } from "../../../../styles/mixin";

export const OptionGroup = styled.div<{
  $defaultWidth?: string;
  $defaultHeight?: string;
  $padding?: string;
  $positionTop?: string;
  $gap?: string;
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;

  width: ${({ $defaultWidth }) => $defaultWidth};
  height: ${({ $defaultHeight }) => $defaultHeight};
  padding: ${({ $padding }) => $padding};
  top: ${({ $positionTop }) => $positionTop};
  gap: ${({ $gap }) => $gap};

  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Secondary, #b1b8c4);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  z-index: 50;

  overflow-y: auto;

  ${scrollBarStyle}
`;
