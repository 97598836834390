"use client";

import styled from "styled-components";

import { DESKTOP_HEADER_HEIGHT } from "../../../../../../../constants";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${DESKTOP_HEADER_HEIGHT};
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.neural.gray02};
  background-color: ${({ theme }) => theme.color.neural.white};
`;

export const HeaderContentsWrapper = styled.div`
  display: flex;
  gap: 3.2rem;
  max-width: 192rem;
  width: 100%;
  padding: var(--Spacing-8, 0.8rem) var(--Spacing-32, 3.2rem);
  height: 100%;

  @media only screen and (min-width: 990px) and (max-width: calc(1200px - 0.1rem)) {
    gap: 2.4rem;
    padding: var(--Spacing-8, 0.8rem) var(--Spacing-24, 2.4rem);
  }

  @media only screen and (min-width: 990px) and (max-width: calc(1024px - 0.1rem)) {
    gap: 1.6rem;
    padding: var(--Spacing-8, 0.8rem) var(--Spacing-16, 1.6rem);
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 22.8rem;
  padding-left: 0.4rem;

  img {
    width: 22.8rem;
    height: 2rem;
  }

  @media only screen and (min-width: 990px) and (max-width: calc(1200px - 0.1rem)) {
    width: 18.2rem;

    svg {
      width: 18.2rem;
      height: 1.6rem;
    }
  }
`;
