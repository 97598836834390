import type { ClassificationCode } from "@/types/code.types";

export const DEFAULT_TOP_FIX_INFO_VALUE = "-";

export const CLASSIFICATION_NAME_FROM_CODE: {
  [key in ClassificationCode]: string;
} = {
  "": "",
  C000: "clinical, preclinical, R&D",
  C001: "clinical trial status",
  C003: "manufacture",
  C004: "conference schedule",
  C005: "conference, poster contents",
  C006: "deal, cooperation",
  C007: "human resource",
  C008: "publication, journal",
  C009: "business, financial update",
  C010: "patent, IP, lawsuit",
  C011: "shareholder, board",
  C012: "FI interaction, other financial news",
  C013: "approval process",
  C014: "stock option",
  C015: "regulatory meeting, committee, guideline",
  C016: "expedited program",
  C017: "reimbursement, coverage, PBM",
  C018: "launch",
  C019: "product performance",
  C020: "etc",
  C021: "venture capital",
};

export const TOP_FIX_INFO_DEFAULT = {
  draftArticleId: {
    title: "Press ID",
    minWidth: "13rem",
    maxWidth: "none",
    width: "fit-content",
  },
  pressDate: {
    title: "Press Date",
    minWidth: "none",
    maxWidth: "20.2rem",
    width: "fit-content",
  },
  companyName: { title: "Company", minWidth: "9.8rem", maxWidth: "none" },
  classificationAiName: {
    title: "AI Classification",
    minWidth: "12rem",
    maxWidth: "24rem",
  },
  classificationManualName: {
    title: "Manual Classification",
    minWidth: "12rem",
    maxWidth: "24rem",
  },
  mcEditorName: {
    title: "MC Editor",
    minWidth: "6.4rem",
    maxWidth: "20rem",
  },
  ftEditorName: {
    title: "FT 1 Editor",
    minWidth: "6.4rem",
    maxWidth: "20rem",
  },
  ftEditor2Name: {
    title: "FT 2 Editor",
    minWidth: "6.4rem",
    maxWidth: "20rem",
  },
  pbEditorName: {
    title: "Publisher",
    minWidth: "6.4rem",
    maxWidth: "20rem",
  },
};
