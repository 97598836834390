import { checkEqualArray, SECTION_CODE_BY_NAME } from "@frontend/common";

import { EntryDataValueInfo } from "@/types/clinicalTrials.types";
import { VentureCapital } from "@/types/ventureCapital.types";

import { ArticleMainLabelList } from "./articleMainLabelList";
import { transformTextListFromEntryDataValue } from "../../../utils/transformTextListFromEntryDataValue";
import { MAIN_LABEL_VALUE_VENTURE_CAPITAL } from "../../constants/mainLabel.constants";
import {
  FormattedMainLabelListType,
  MainLabelArticleSectionCode,
  MainLabelEntityTypes,
  MainLabelFormatTypes,
  MainLabelInfoData,
  MainLabelListForUpdateType,
  MainLabelListType,
  MainLabelNames,
  VentureCapitalMainLabelNames,
} from "../../types/articleMainLabel.types";

export class VentureCapitalMainLabelList extends ArticleMainLabelList {
  #targetSection = "Venture Capital" as const;

  mainLabelList: MainLabelListType;

  mainLabelPathOfEntryData: Record<
    string,
    (data: { "Venture Capital"?: Partial<VentureCapital> }) => string[]
  >;

  constructor() {
    super();
    this.mainLabelPathOfEntryData = MAIN_LABEL_VALUE_VENTURE_CAPITAL;
    this.mainLabelList = [];
  }

  public getArticleMainLabelList(): MainLabelListType {
    return this.mainLabelList;
  }

  // eslint-disable-next-line class-methods-use-this
  public getFormattedMainLabelList(
    defaultMainLabelList: MainLabelInfoData,
    mainLabelList: MainLabelListType,
  ): FormattedMainLabelListType {
    const formattedLabelList = defaultMainLabelList.map((labelInfo) => {
      const targetValueList = mainLabelList
        .filter((label) => label.labelName === labelInfo.labelName)
        .map((label) => label.labelValue);

      return {
        ...labelInfo,
        value: [...new Set(targetValueList)] || [],
      };
    });

    return formattedLabelList;
  }

  public createMainLabelList(
    defaultMainLabelList: MainLabelInfoData,
    entryData: {
      "Venture Capital"?: Partial<VentureCapital>;
    },
  ): MainLabelListForUpdateType {
    const temp: MainLabelListForUpdateType = [];
    defaultMainLabelList.forEach((labelInfo) => {
      const targetValue =
        labelInfo.type === "MLT005"
          ? this.mainLabelPathOfEntryData[labelInfo.labelName](
              entryData,
            )?.filter((_, index) => index === 0)
          : this.mainLabelPathOfEntryData[labelInfo.labelName](entryData);
      if (targetValue) {
        targetValue.map((value) =>
          temp.push({
            ...labelInfo,
            value,
            id: "",
          }),
        );
      }
    });

    return temp;
  }

  public getTargetOptionList(
    labelName: MainLabelNames,
    entryData: {
      "Venture Capital"?: Partial<VentureCapital>;
    },
  ): string[] {
    const VENTURE_CAPITAL_TARGET_VALUE: Record<
      VentureCapitalMainLabelNames,
      EntryDataValueInfo[] | []
    > = {
      Modality:
        entryData?.[this.#targetSection]?.[
          "Drug Profile"
        ]?.[0]?.Modality?.value?.map((item) => ({
          ...item,
          text: item.text.replace("(etc)", "").trim(),
        })) || [],
      Target:
        entryData?.[this.#targetSection]?.["Drug Profile"]?.[0]?.Target.value ||
        [],
      "Drug Name": [],
      Phase: [],
      "Target Indication":
        entryData?.[this.#targetSection]?.["Drug Profile"]?.[0]?.[
          "Target Indication"
        ]?.value || [],
      "Round Type": [],
    };

    const MAIN_LABEL_OPTION_LIST: Record<
      VentureCapitalMainLabelNames,
      string[]
    > = {
      Modality: VENTURE_CAPITAL_TARGET_VALUE.Modality
        ? transformTextListFromEntryDataValue(
            VENTURE_CAPITAL_TARGET_VALUE.Modality,
          )
        : [],
      Target: VENTURE_CAPITAL_TARGET_VALUE.Target
        ? transformTextListFromEntryDataValue(
            VENTURE_CAPITAL_TARGET_VALUE.Target,
          )
        : [],
      "Drug Name": [],
      Phase: [],
      "Target Indication": VENTURE_CAPITAL_TARGET_VALUE["Target Indication"]
        ? transformTextListFromEntryDataValue(
            VENTURE_CAPITAL_TARGET_VALUE["Target Indication"],
          )
        : [],
      "Round Type": [],
    };

    return MAIN_LABEL_OPTION_LIST[labelName as VentureCapitalMainLabelNames];
  }

  public entryDataCompatibilityWithMainLabelsData(
    defaultMainLabelList: MainLabelInfoData,
    mainLabelList: MainLabelListType,
    entryData: {
      "Venture Capital"?: Partial<VentureCapital>;
    },
  ): Record<VentureCapitalMainLabelNames, boolean> {
    const checkTargetMainLabelValue = (
      labelName: VentureCapitalMainLabelNames,
    ) => {
      return this.getFormattedMainLabelList(
        defaultMainLabelList,
        mainLabelList,
      )?.filter((label) => label.labelName === labelName)?.[0]?.value;
    };

    const CHECK_DEALS_TARGET_MAIN_LABEL: Record<
      VentureCapitalMainLabelNames,
      boolean
    > = {
      "Drug Name": !!(
        this.mainLabelPathOfEntryData["Drug Name"]?.(entryData)?.[0] ===
        checkTargetMainLabelValue("Drug Name")?.[0]
      ),
      Target:
        checkTargetMainLabelValue("Target")?.length === 0 ||
        checkTargetMainLabelValue("Target")?.filter((value) =>
          this.mainLabelPathOfEntryData.Target(entryData)?.includes(value),
        )?.length === checkTargetMainLabelValue("Target")?.length,
      Modality: this.mainLabelPathOfEntryData
        .Modality?.(entryData)
        .includes(checkTargetMainLabelValue("Modality")?.[0]),
      Phase: !!(
        this.mainLabelPathOfEntryData.Phase?.(entryData)?.[0] ===
        checkTargetMainLabelValue("Phase")?.[0]
      ),
      "Target Indication": this.mainLabelPathOfEntryData["Target Indication"]?.(
        entryData,
      ).includes(checkTargetMainLabelValue("Target Indication")?.[0]),
      "Round Type": !!checkEqualArray(
        this.mainLabelPathOfEntryData["Round Type"]?.(entryData),
        checkTargetMainLabelValue("Round Type"),
      ),
    };

    return CHECK_DEALS_TARGET_MAIN_LABEL;
  }

  public mainLabelListForEntryDataCompatibility(
    defaultMainLabelList: MainLabelInfoData,
    mainLabelList: MainLabelListType,
    entryData: {
      "Venture Capital"?: Partial<VentureCapital>;
    },
  ): MainLabelListForUpdateType | null {
    const entryDataCompatibilityWithMainLabels =
      this.entryDataCompatibilityWithMainLabelsData(
        defaultMainLabelList,
        mainLabelList,
        entryData,
      );

    const isNeedUpdate =
      Object.values(entryDataCompatibilityWithMainLabels).every(
        (value) => value,
      ) === false;

    if (!isNeedUpdate) return null;

    const needToUpdateMainLabelKeys = Object.entries(
      entryDataCompatibilityWithMainLabels,
    )
      .map(([key, value]) => {
        if (value === false) return key;
        return null;
      })
      .filter((value) => value !== null) as MainLabelNames[];

    const formattedMainLabelList = this.getFormattedMainLabelList(
      defaultMainLabelList,
      mainLabelList,
    );

    const mainLabelListForEmptyValue = needToUpdateMainLabelKeys
      ?.filter((updatedLabel) => {
        const defaultLabels = mainLabelList?.map((label) => label.labelName);

        return (
          defaultLabels.filter((label) => label === updatedLabel).length === 0
        );
      })
      .map((label) => {
        const targetLabel = formattedMainLabelList.find(
          (item) => item.labelName === label,
        );

        if (!targetLabel) return null;
        return {
          id: "",
          labelName: label,
          type: targetLabel.type,
          articleSection: targetLabel.articleSection,
          entity: targetLabel.entity,
          labelValue: "",
        };
      })
      .filter((value) => value !== null);

    const newMainLabelList = [
      ...mainLabelList,
      ...mainLabelListForEmptyValue,
    ]?.map((label) => {
      if (label === null) return;
      if (
        label.labelName === "Target" &&
        needToUpdateMainLabelKeys.includes("Target")
      ) {
        return {
          id: String(label.id),
          labelName: label.labelName,
          type: label.type,
          articleSection: label.articleSection,
          entity: label.entity,
          value: "",
        };
      }
      if (needToUpdateMainLabelKeys.find((key) => key === label.labelName)) {
        // NOTE : displayAllValues 메인 라벨이 여러개일 때, 표시되는 라벨을 정의합니다.
        const displayAllValues = ["Round Type"];
        if (displayAllValues.includes(label.labelName)) {
          return {
            id: String(label.id),
            labelName: label.labelName,
            type: label.type,
            articleSection: label.articleSection,
            entity: label.entity,
            value: "",
          };
        }

        return {
          id: String(label.id),
          labelName: label.labelName,
          type: label.type,
          articleSection: label.articleSection,
          entity: label.entity,
          value: this.mainLabelPathOfEntryData[label.labelName](entryData)[0],
        };
      }
      return {
        id: String(label.id),
        labelName: label.labelName,
        type: label.type,
        articleSection: label.articleSection,
        entity: label.entity,
        value: label.labelValue,
      };
    }) as {
      id: string;
      labelName: VentureCapitalMainLabelNames;
      type: MainLabelFormatTypes;
      articleSection: MainLabelArticleSectionCode;
      entity: MainLabelEntityTypes;
      value: string;
    }[];

    if (
      needToUpdateMainLabelKeys.find((key) => key === "Target") ||
      needToUpdateMainLabelKeys.find((key) => key === "Round Type")
    ) {
      const targetMainLabelData = this.mainLabelPathOfEntryData
        .Target?.(entryData)
        ?.map((value) => ({
          id: "",
          labelName: "Target" as VentureCapitalMainLabelNames,
          type: "MLT004" as MainLabelFormatTypes,
          articleSection:
            SECTION_CODE_BY_NAME.ventureCapital as MainLabelArticleSectionCode,
          entity: "E020" as MainLabelEntityTypes,
          value,
        }));

      const roundTypeMainLabelData = this.mainLabelPathOfEntryData?.[
        "Round Type"
      ]?.(entryData)?.map((value) => ({
        id: "",
        labelName: "Round Type" as VentureCapitalMainLabelNames,
        type: "MLT002" as MainLabelFormatTypes,
        articleSection:
          SECTION_CODE_BY_NAME.ventureCapital as MainLabelArticleSectionCode,
        entity: "E060" as MainLabelEntityTypes,
        value,
      }));

      return [
        ...newMainLabelList,
        ...targetMainLabelData,
        ...roundTypeMainLabelData,
      ];
    }

    return newMainLabelList.filter(
      (item) => !(item.id === "" && item.value === undefined),
    );
  }
}
