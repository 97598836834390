"use client";

import styled from "styled-components";

export const ButtonWrapper = styled.button<{
  $width: string;
  $height: string;
  $styleType: "fill";
}>`
  display: flex;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  min-height: var(--Size-Btn-Sm, 2.8rem);
  max-height: var(--Size-Btn-Lg, 4.8rem);
  padding: var(--Spacing-Btn-8, 0.8rem) var(--Spacing-Btn-16, 1.6rem);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-Btn-4, 0.4rem);
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);

  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  font-size: 1.4rem;
  font-weight: 500;

  &:hover {
    border-radius: var(--Border-Radius-4, 0.4rem);
    background: var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }

  &:active {
    border-radius: var(--Border-Radius-4, 0.4rem);
    background: var(--Color-Common-Bg-Interactive-Primary-Pressed, #017abc);

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
      0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;
  }

  &:disabled {
    border-radius: var(--Border-Radius-4, 0.4rem);
    background: var(--Color-Common-Bg-Disabled, #cbd0d8);
  }
`;
