"use client";

import styled from "styled-components";

export const PhaseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;
`;

export const PhaseIcon = styled.div``;
