"use client";

import styled, { css } from "styled-components";

export const SubMenuWrapper = styled.div`
  position: absolute;
  top: 105%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem 0;
`;

export const SubMenuList = styled.ul`
  width: 100%;
  height: 100%;

  display: flex;
  padding: var(--Spacing-4, 0.4rem) var(--Spacing-8, 0.8rem);
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Primary, #fff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);
`;

export const SubMenuItem = styled.li<{
  $isSelected: boolean;
  $isUpcoming: boolean;
  $isEnabled: boolean;
}>`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  width: max-content;
  color: ${({ theme }) => theme.color.neural.black};
  text-align: center;
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 100%;
  padding: 1.15rem 1.6rem;
  cursor: pointer;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: ${({ theme }) => theme.color.node.sky};
    `}
  ${({ $isUpcoming }) => $isUpcoming && css``}

  ${({ $isEnabled }) =>
    !$isEnabled &&
    css`
      cursor: not-allowed;
    `}

  &:hover {
    color: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);
  }
`;

export const UpcomingTag = styled.span`
  color: ${({ theme }) => theme.color.neural.black};
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.1rem;
  font-weight: 200;
  line-height: 100%;
  font-style: italic;
`;
