import { checkEqualArray, SECTION_CODE_BY_NAME } from "@frontend/common";

import { EntryDataValueInfo } from "@/types/clinicalTrials.types";
import { Deals } from "@/types/deals.types";

import { ArticleMainLabelList } from "./articleMainLabelList";
import { transformTextListFromEntryDataValue } from "../../../utils/transformTextListFromEntryDataValue";
import { MAIN_LABEL_VALUE_DEALS } from "../../constants/mainLabel.constants";
import {
  FormattedMainLabelListType,
  MainLabelArticleSectionCode,
  MainLabelEntityTypes,
  MainLabelFormatTypes,
  MainLabelInfoData,
  MainLabelListForUpdateType,
  MainLabelListType,
  DealsMainLabelNames,
  MainLabelNames,
} from "../../types/articleMainLabel.types";

export class DealsArticleMainLabelList extends ArticleMainLabelList {
  #targetSection = "Deals" as const;

  mainLabelList: MainLabelListType;

  mainLabelPathOfEntryData: Record<
    string,
    (data: { Deals?: Partial<Deals> }) => string[]
  >;

  constructor() {
    super();
    this.mainLabelPathOfEntryData = MAIN_LABEL_VALUE_DEALS;
    this.mainLabelList = [];
  }

  public getArticleMainLabelList(): MainLabelListType {
    return this.mainLabelList;
  }

  // eslint-disable-next-line class-methods-use-this
  public getFormattedMainLabelList(
    defaultMainLabelList: MainLabelInfoData,
    mainLabelList: MainLabelListType,
  ): FormattedMainLabelListType {
    const formattedLabelList = defaultMainLabelList.map((labelInfo) => {
      const targetValueList = mainLabelList
        .filter((label) => label.labelName === labelInfo.labelName)
        .map((label) => label.labelValue);

      return {
        ...labelInfo,
        value: [...new Set(targetValueList)] || [],
      };
    });

    return formattedLabelList;
  }

  public createMainLabelList(
    defaultMainLabelList: MainLabelInfoData,
    entryData: {
      Deals?: Partial<Deals>;
    },
  ): MainLabelListForUpdateType {
    const temp: MainLabelListForUpdateType = [];
    defaultMainLabelList.forEach((labelInfo) => {
      const targetValue =
        labelInfo.type === "MLT005"
          ? this.mainLabelPathOfEntryData[labelInfo.labelName](
              entryData,
            )?.filter((_, index) => index === 0)
          : this.mainLabelPathOfEntryData[labelInfo.labelName](entryData);
      if (targetValue) {
        targetValue.map((value) =>
          temp.push({
            ...labelInfo,
            value,
            id: "",
          }),
        );
      }
    });

    return temp;
  }

  public getTargetOptionList(
    labelName: MainLabelNames,
    entryData: {
      Deals?: Partial<Deals>;
    },
  ): string[] {
    const DEALS_TARGET_VALUE: Record<
      DealsMainLabelNames,
      EntryDataValueInfo[] | []
    > = {
      Modality:
        entryData?.[this.#targetSection]?.[
          "Drug Profile"
        ]?.[0]?.Modality?.value?.map((item) => ({
          ...item,
          text: item.text.replace("(etc)", "").trim(),
        })) || [],
      Target:
        entryData?.[this.#targetSection]?.["Drug Profile"]?.[0]?.Target.value ||
        [],
      "Drug Name": [],
      Phase: [],
      "Target Indication":
        entryData?.[this.#targetSection]?.["Drug Profile"]?.[0]?.[
          "Target Indication"
        ]?.value || [],
      "Deal Type": [],
    };

    const MAIN_LABEL_OPTION_LIST: Record<DealsMainLabelNames, string[]> = {
      Modality: DEALS_TARGET_VALUE.Modality
        ? transformTextListFromEntryDataValue(DEALS_TARGET_VALUE.Modality)
        : [],
      Target: DEALS_TARGET_VALUE.Target
        ? transformTextListFromEntryDataValue(DEALS_TARGET_VALUE.Target)
        : [],
      "Drug Name": [],
      Phase: [],
      "Target Indication": DEALS_TARGET_VALUE["Target Indication"]
        ? transformTextListFromEntryDataValue(
            DEALS_TARGET_VALUE["Target Indication"],
          )
        : [],
      "Deal Type": [],
    };

    return MAIN_LABEL_OPTION_LIST[labelName as DealsMainLabelNames];
  }

  public entryDataCompatibilityWithMainLabelsData(
    defaultMainLabelList: MainLabelInfoData,
    mainLabelList: MainLabelListType,
    entryData: {
      Deals?: Partial<Deals>;
    },
  ): Record<DealsMainLabelNames, boolean> {
    const checkTargetMainLabelValue = (labelName: DealsMainLabelNames) => {
      return this.getFormattedMainLabelList(
        defaultMainLabelList,
        mainLabelList,
      )?.filter((label) => label.labelName === labelName)?.[0]?.value;
    };

    const CHECK_DEALS_TARGET_MAIN_LABEL: Record<DealsMainLabelNames, boolean> =
      {
        "Drug Name": !!(
          this.mainLabelPathOfEntryData["Drug Name"]?.(entryData)?.[0] ===
          checkTargetMainLabelValue("Drug Name")?.[0]
        ),
        Target:
          checkTargetMainLabelValue("Target")?.length === 0 ||
          checkTargetMainLabelValue("Target")?.filter((value) =>
            this.mainLabelPathOfEntryData.Target(entryData)?.includes(value),
          )?.length === checkTargetMainLabelValue("Target")?.length,
        Modality: this.mainLabelPathOfEntryData
          .Modality?.(entryData)
          .includes(checkTargetMainLabelValue("Modality")?.[0]),
        Phase: !!(
          this.mainLabelPathOfEntryData.Phase?.(entryData)?.[0] ===
          checkTargetMainLabelValue("Phase")?.[0]
        ),
        "Target Indication": this.mainLabelPathOfEntryData[
          "Target Indication"
        ]?.(entryData).includes(
          checkTargetMainLabelValue("Target Indication")?.[0],
        ),
        "Deal Type": !!checkEqualArray(
          this.mainLabelPathOfEntryData["Deal Type"]?.(entryData),
          checkTargetMainLabelValue("Deal Type"),
        ),
      };

    return CHECK_DEALS_TARGET_MAIN_LABEL;
  }

  public mainLabelListForEntryDataCompatibility(
    defaultMainLabelList: MainLabelInfoData,
    mainLabelList: MainLabelListType,
    entryData: {
      Deals?: Partial<Deals>;
    },
  ): MainLabelListForUpdateType | null {
    const entryDataCompatibilityWithMainLabels =
      this.entryDataCompatibilityWithMainLabelsData(
        defaultMainLabelList,
        mainLabelList,
        entryData,
      );

    const isNeedUpdate =
      Object.values(entryDataCompatibilityWithMainLabels).every(
        (value) => value,
      ) === false;

    if (!isNeedUpdate) return null;

    const needToUpdateMainLabelKeys = Object.entries(
      entryDataCompatibilityWithMainLabels,
    )
      .map(([key, value]) => {
        if (value === false) return key;
        return null;
      })
      .filter((value) => value !== null) as MainLabelNames[];

    const formattedMainLabelList = this.getFormattedMainLabelList(
      defaultMainLabelList,
      mainLabelList,
    );

    const mainLabelListForEmptyValue = needToUpdateMainLabelKeys
      ?.filter((updatedLabel) => {
        const defaultLabels = mainLabelList?.map((label) => label.labelName);

        return (
          defaultLabels.filter((label) => label === updatedLabel).length === 0
        );
      })
      .map((label) => {
        const targetLabel = formattedMainLabelList.find(
          (item) => item.labelName === label,
        );

        if (!targetLabel) return null;
        return {
          id: "",
          labelName: label,
          type: targetLabel.type,
          articleSection: targetLabel.articleSection,
          entity: targetLabel.entity,
          labelValue: "",
        };
      })
      .filter((value) => value !== null);

    const newMainLabelList = [
      ...mainLabelList,
      ...mainLabelListForEmptyValue,
    ]?.map((label) => {
      if (label === null) return;
      if (
        label.labelName === "Target" &&
        needToUpdateMainLabelKeys.includes("Target")
      ) {
        return {
          id: String(label.id),
          labelName: label.labelName,
          type: label.type,
          articleSection: label.articleSection,
          entity: label.entity,
          value: "",
        };
      }
      if (needToUpdateMainLabelKeys.find((key) => key === label.labelName)) {
        // NOTE : displayAllValues 메인 라벨이 여러개일 때, 표시되는 라벨을 정의합니다.
        const displayAllValues = ["Deal Type"];
        if (displayAllValues.includes(label.labelName)) {
          return {
            id: String(label.id),
            labelName: label.labelName,
            type: label.type,
            articleSection: label.articleSection,
            entity: label.entity,
            value: "",
          };
        }

        return {
          id: String(label.id),
          labelName: label.labelName,
          type: label.type,
          articleSection: label.articleSection,
          entity: label.entity,
          value: this.mainLabelPathOfEntryData[label.labelName](entryData)[0],
        };
      }
      return {
        id: String(label.id),
        labelName: label.labelName,
        type: label.type,
        articleSection: label.articleSection,
        entity: label.entity,
        value: label.labelValue,
      };
    }) as {
      id: string;
      labelName: DealsMainLabelNames;
      type: MainLabelFormatTypes;
      articleSection: MainLabelArticleSectionCode;
      entity: MainLabelEntityTypes;
      value: string;
    }[];

    if (
      needToUpdateMainLabelKeys.find((key) => key === "Target") ||
      needToUpdateMainLabelKeys.find((key) => key === "Deal Type")
    ) {
      const targetMainLabelData = this.mainLabelPathOfEntryData
        .Target?.(entryData)
        ?.map((value) => ({
          id: "",
          labelName: "Target" as DealsMainLabelNames,
          type: "MLT004" as MainLabelFormatTypes,
          articleSection:
            SECTION_CODE_BY_NAME.deals as MainLabelArticleSectionCode,
          entity: "E020" as MainLabelEntityTypes,
          value,
        }));

      const dealTypeMainLabelData = this.mainLabelPathOfEntryData?.[
        "Deal Type"
      ]?.(entryData)?.map((value) => ({
        id: "",
        labelName: "Deal Type" as DealsMainLabelNames,
        type: "MLT002" as MainLabelFormatTypes,
        articleSection:
          SECTION_CODE_BY_NAME.deals as MainLabelArticleSectionCode,
        entity: "E060" as MainLabelEntityTypes,
        value,
      }));

      return [
        ...newMainLabelList,
        ...targetMainLabelData,
        ...dealTypeMainLabelData,
      ];
    }

    return newMainLabelList.filter(
      (item) => !(item.id === "" && item.value === undefined),
    );
  }
}
