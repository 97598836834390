import { ENTRY_DATA_SECTION_KEYS as SECTION_KEYS } from "@frontend/common/src/constants/entryData";

import { ArticleSectionType } from "@FillInTableContainer/types/fillInTable.types";

import type { ClassificationCode } from "@/types/code.types";

export const ARTICLE_SECTION_BY_CLASSIFICATION: Record<
  ClassificationCode,
  ArticleSectionType | "none"
> = {
  C000: SECTION_KEYS.clinicalTrial,
  C001: SECTION_KEYS.clinicalTrialStatus,
  C003: "none",
  C004: "none",
  C005: SECTION_KEYS.clinicalTrial,
  C006: "Deals",
  C007: "none",
  C008: "none",
  C009: "none",
  C010: "none",
  C011: "none",
  C012: "none",
  C013: SECTION_KEYS.regulatory,
  C014: "none",
  C015: "none",
  C016: SECTION_KEYS.expeditedProgram,
  C017: "none",
  C018: "none",
  C019: "none",
  C020: "none",
  C021: SECTION_KEYS.ventureCapital,
  "": "none",
};
