import { ENTRY_DATA_SECTION_KEYS as SECTION_KEYS } from "@frontend/common";

export const ARTICLE_SECTION_CODE = {
  C000: SECTION_KEYS.clinicalTrial,
  C001: SECTION_KEYS.clinicalTrialStatus,
  C003: "Clinical Trials",
  C004: "Clinical Trials",
  C005: SECTION_KEYS.clinicalTrial,
  C006: SECTION_KEYS.deals,
  C007: "Clinical Trials",
  C008: "Clinical Trials",
  C009: "Clinical Trials",
  C010: "Clinical Trials",
  C011: "Clinical Trials",
  C012: "Clinical Trials",
  C013: SECTION_KEYS.regulatory,
  C014: "Clinical Trials",
  C015: "Clinical Trials",
  C016: SECTION_KEYS.expeditedProgram,
  C017: "Clinical Trials",
  C018: "Clinical Trials",
  C019: "Clinical Trials",
  C020: "Clinical Trials",
  C021: SECTION_KEYS.ventureCapital,
} as const;
