"use client";

import { styled } from "styled-components";

export const ModuleLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-6, 0.6rem);
  flex: 1 0 0;
`;

export const BorderBox = styled.div`
  border-radius: var(--Border-Radius-8, 0.8rem);
  border: var(--Border-Width-1, 1px) solid
    var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);

  display: flex;
  padding: var(--Spacing-8, 0.8rem);
  flex-direction: column;
  gap: var(--Spacing-8, 0.8rem);
`;

export const TopContent = styled.div`
  display: flex;
  gap: var(--Spacing-8, 0.8rem);
  justify-content: space-between;
  align-items: flex-start;
`;

export const TopInfoWrapper = styled.div`
  display: flex;
  padding: var(--Spacing-4, 0.4rem);
  flex-direction: column;
  justify-content: center;
  gap: 0.2rem;
`;

export const LegendSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: var(--Spacing-4, 0.4rem);
  padding: var(--Spacing-4, 0.4rem);
`;

export const ChartSectionWrapper = styled.div`
  display: flex;
  height: 18.3rem;
  flex-direction: column;
  gap: var(--Spacing-4, 0.4rem);
`;

export const Chart = styled.div`
  height: 16rem;
  background-color: white;
  position: relative;
`;

export const DashLine = styled.div`
  position: absolute;
  width: 90%;
  height: 0.1rem;
  border-bottom: 0.1rem dashed #b1b8c4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const XAxisLine = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: #e5e7eb;
`;

export const XAxisTicksList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
`;
