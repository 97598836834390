export const MIN_GROUP_SIZE = 1000000;
export const MIN_ITEM_SIZE = 60000;
export const INIT_GROUP_SIZE = 160000;
export const TREE_MAP_RADIUS = 8;

export const MODALITY_SHELL_BACKGROUND_BY_GROUP_RANK: Record<number, string> = {
  1: "#480E74",
  2: "#5A1291",
  3: "#6C15AE",
  4: "#7E19CB",
  5: "#9D3CE7",
  6: "#AB58EB",
  7: "#B974EE",
  8: "#C790F2",
  9: "#D5ABF5",
};

export const MODALITY_SHELL_BACKGROUND_BY_ITEM_RANK: Record<number, string> = {
  1: "#F5F0F8",
  2: "#E0D3EA",
  3: "#CCB6DB",
  4: "#B799CD",
  5: "#A37CBF",
  6: "#8E5FB1",
  7: "#724C8E",
};

export const GROUP_TOOLTIP_BACKGROUND = {
  MODALITY: "#EAD6FA",
  THERAPEUTIC_AREA: "#FDE7F2",
  PHASE: "#FFFAA3",
} as const;

export const GROUP_TOOLTIP_BORDER_COLOR = {
  MODALITY: "#EAD6FA",
  THERAPEUTIC_AREA: "#FDE7F2",
  PHASE: "#FFFAA3",
} as const;
export const GROUP_TOOLTIP_TITLE_COLOR = {
  MODALITY: "#8219D2",
  THERAPEUTIC_AREA: "#DB0F72",
  PHASE: "#615C00",
} as const;

export const GROUP_TOOLTIP_CONTENTS_BACKGROUND = {
  MODALITY: "#F5EBFD",
  THERAPEUTIC_AREA: "#FFF4F9",
  PHASE: "#fffdd1",
} as const;

export const NO_FOCUS_ITEM_HOVER_BACKGROUND = {
  MODALITY: "#faf5fe",
  THERAPEUTIC_AREA: "#FFF9FC",
  PHASE: "#fffae5",
} as const;

export const FOCUS_ITEM_HOVER_BACKGROUND = {
  MODALITY: "#f0e0fb",
  THERAPEUTIC_AREA: "#FEE9F3",
  PHASE: "#fff4cb",
} as const;

export const FONT_SIZE_BY_RANK = {
  1: 24 + 10,
  2: 18 + 10,
  3: 14 + 10,
  4: 12 + 10,
  5: 11 + 10,
  6: 10 + 18,
  7: 6 + 18,
};

export const LINE_HEIGHT_BY_RANK = {
  1: 27,
  2: 24,
  3: 15.6,
  4: 15.6,
  5: 14.3,
  6: 13,
  7: 10,
};
