"use client";

import styled, { css } from "styled-components";

export const HeaderMenuDesktopWrapper = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

export const DesktopMenu = styled.li<{
  $isEnabled: boolean;
  $isSelected?: boolean;
  $isHaveSubMenu?: boolean;
}>`
  position: relative;
  height: 100%;
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-weight: 500;

  padding: ${({ $isHaveSubMenu }) =>
    $isHaveSubMenu
      ? "var(--Spacing-6, 0.6rem) var(--Spacing-8, 0.8rem) var(--Spacing-6, 0.6rem) var(--Spacing-12, 1.2rem)"
      : "var(--Spacing-6, 0.6rem) var(--Spacing-12, 1.2rem)"};

  ${({ $isEnabled }) =>
    !$isEnabled &&
    css`
      > div {
        cursor: not-allowed;
      }
    `}
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: var(--Color-Common-Text-Interactive-Selected, #0198e9);

      svg {
        path {
          fill: var(--Color-Common-Text-Interactive-Selected, #0198e9);
        }
      }
    `};

  @media only screen and (min-width: 990px) and (max-width: calc(1200px - 0.1rem)) {
    padding: ${({ $isHaveSubMenu }) =>
      $isHaveSubMenu
        ? "var(--Spacing-6, 0.6rem) var(--Spacing-4, 0.4rem) var(--Spacing-6, 0.6rem) var(--Spacing-8, 0.8rem)"
        : "var(--Spacing-6, 0.6rem) var(--Spacing-8, 0.8rem)"};
  }

  &:hover {
    color: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);

    svg {
      path {
        fill: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);
      }
    }
  }
`;

export const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 0.4rem;
  cursor: pointer;

  @media only screen and (min-width: 990px) and (max-width: calc(1200px - 0.1rem)) {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const DisableReason = styled.span<{ $isBeta?: boolean }>`
  position: absolute;
  top: ${({ $isBeta }) => ($isBeta ? "-0.9rem" : "0.9rem")};
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1rem;
  font-style: italic;
  color: ${({ theme, $isBeta }) =>
    $isBeta ? theme.color.neural.gray05 : theme.color.neural.black};
`;
