import React, { ForwardedRef, HTMLAttributes, forwardRef } from "react";

import * as S from "./SelectField.style";
import { IconChevronDown, IconChevronUp } from "../../../Icons";

interface Props extends HTMLAttributes<HTMLInputElement> {
  placeholder: string;
  width?: string;
  height?: string;
  styleType: "line" | "transparent";
  status: "focus" | "error" | "disabled" | "default";
  isFilledTextColor?: boolean;
  value?: string;
  svgDataId?: string;
  containerClickHandler?: () => void;
  leftIcon?: React.ReactNode;
  selectCount?: React.ReactNode;
}

const SelectField = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder = "Placeholder",
      width = "24rem",
      height = "3.2rem",
      styleType = "line",
      status = "default",
      isFilledTextColor = false,
      svgDataId,
      value,
      containerClickHandler,
      leftIcon,
      selectCount,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <S.SelectFieldWrapper
        $width={width}
        $height={height}
        $status={status}
        $styleType={styleType}
        onClick={containerClickHandler}
      >
        {leftIcon && <>{leftIcon}</>}
        <S.SelectFieldInput
          ref={ref}
          placeholder={placeholder}
          disabled={status === "disabled"}
          $isFilledTextColor={isFilledTextColor}
          value={value}
          readOnly
          {...rest}
        />
        {selectCount}
        {status === "focus" ? (
          <IconChevronUp color="#0198E9" svgDataId={svgDataId} />
        ) : (
          <IconChevronDown
            color={
              status === "error" && styleType === "transparent"
                ? "#E53E06"
                : "#515C6C"
            }
            svgDataId={svgDataId}
          />
        )}
      </S.SelectFieldWrapper>
    );
  },
);

export default SelectField;
