"use client";

import styled, { css } from "styled-components";

interface SkeletonStyleProps {
  $width?: string;
  $height?: string;
  $borderRadius?: string;
  $enableBorder: boolean;
  $isRatio?: boolean;
}

export const SkeletonAnimation = css`
  @-webkit-keyframes skeleton-gradient {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes skeleton-gradient {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  background-image: linear-gradient(
    90deg,
    #e5e7eb 0%,
    #e5e7eb 16.66%,
    rgba(229, 231, 235, 0.5) 33.33%,
    #e5e7eb 50%,
    #e5e7eb 66.66%,
    rgba(229, 231, 235, 0.5) 83.33%,
    #e5e7eb 100%
  );
  background-size: 200% 100%;
  -webkit-animation: skeleton-gradient 3s linear infinite;
  animation: skeleton-gradient 3s linear infinite;
`;

export const SkeletonBox = styled.div<SkeletonStyleProps>`
  border-radius: ${({ $borderRadius }) => $borderRadius};
  background: linear-gradient(
    90deg,
    #e5e7eb 25%,
    rgba(229, 231, 235, 0.5) 50%,
    #e5e7eb 75%
  );
  background-size: 200% 100%;
  background-position: 200% 0;

  ${SkeletonAnimation}
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};

  ${({ $enableBorder }) =>
    $enableBorder &&
    css`
      border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
    `}

  ${({ $isRatio, $height }) =>
    $isRatio &&
    $height &&
    css`
      height: clamp(${parseFloat($height) / 1.4}px, 3vw, ${$height});
    `}
`;
